<template>
  <div class="dashboard mb-5">
    <div class="row mb-4">
      <div class="col-xl-3 col-md-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                  Total Groups
                </p>
              </div>
            </div>
            <div class="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h5 class="fs-22 fw-semibold ff-secondary mb-4">
                  {{result.groups.total }}
                </h5>
                <router-link to="/groups" class="small text-decoration-underline"
                  >View groups</router-link>
              </div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-danger rounded fs-3">
                  <i class="ri-sort-desc text-danger"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
       <div class="col-xl-3 col-md-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <p  class="text-uppercase fw-medium text-muted text-truncate mb-0" >
                 All Users
                </p>
              </div>
            </div>
            <div class="d-flex align-items-end justify-content-between mt-4" >
              <div>
                <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                  {{result.users.total}}
                </h4>
                <router-link to="/users" class="small text-decoration-underline"
                  >See details</router-link>
              </div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-info rounded fs-3">
                  <i class="ri ri-team-line text-info"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                  All Group heads
                </p>
              </div>
            </div>
            <div class="d-flex align-items-end justify-content-between mt-4" >
              <div>
                <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                  {{result.users.grouphead}}
                </h4>
                <router-link to="/users?role=grouphead" class="small text-decoration-underline" >See details</router-link>
              </div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-primary rounded fs-3">
                  <i class="ri-team-line text-primary"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <div class="col-xl-3 col-md-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <p class="text-uppercase fw-medium text-muted text-truncate mb-0" >
                  All Members
                </p>
              </div>
            </div>
            <div class="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                  {{result.members.total}}
                </h4>
                <router-link to="/members" class="text-decoration-underline" >See details</router-link>
              </div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-warning rounded fs-3">
                  <i class="ri-team-line text-warning"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <statistic-apex-count
          title="Verified Users"
          :count-end="result.users.verified_users"
          :total="result.users.total"
          :figure="result.users.verified_users"
          />
      </div>
      <div class="col-md-6">
        <statistic-apex-count
          title="Unverified Users"
          :count-end="result.users.unverified_users"
          :total="result.users.total"
          :figure="result.users.unverified_users"
          />
      </div>
    </div>
  </div>
</template>

<script>

import StatisticApexCount from '@/components/widgets/StatisticApexCount.vue';

export default {
    name: 'dashboard',
    components:{
      StatisticApexCount,
    },
    computed:{
      activeUser(){
        return this.$store.state.AppActiveUser
      },
      result(){
        return this.$store.state.dashboardData
      }
    },
    created(){
      this.$http.get('/analytics')
      .then((response) => {
        if(response.data.success){
          this.$store.commit('UPDATE_DASHBOARD', response.data.data)
        }
      })
    }
}
</script>

